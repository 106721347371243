<script setup lang="ts">
    import { cn } from '@/utils/ui';
    import type { HTMLAttributes } from 'vue';

    const model = defineModel<string>();

    const props = defineProps<{
        label?: string;
        name: string;
        required?: boolean;
        placeholder: string;
        error?: string | undefined;
        class?: HTMLAttributes['class'];
    }>();

    const id = useId();
</script>

<template>
    <div class="w-full">
        <Label v-if="props.label" :for="id" :required="props.required" class="font-bold">
            {{ props.label }}
        </Label>
        <input
            :id="id"
            v-model="model"
            type="text"
            :name="props.name"
            :placeholder="props.placeholder"
            :class="
                cn(
                    'text-inherit relative w-full truncate rounded-lg border-2 border-blue-200 bg-shades-white py-3 pl-4 pr-8 text-left text-blue-900 outline-none transition-all duration-300 placeholder:text-neutral-400 focus:border-blue-400',
                    props.class,
                )
            " />
        <p
            class="mt-1 flex h-6 gap-2 pl-2 text-sm text-red-400 transition-opacity duration-300"
            :class="props.error ? 'opacity-100' : 'opacity-0'">
            <IconsAlertCircle class="mt-1 h-5 w-5 stroke-red-400"></IconsAlertCircle
            >{{ props.error }}
        </p>
    </div>
</template>
